import axios from 'axios'
import store from '~/store'

export default {
    data: () => ({
        page: null,
        includes: null,
        report: {},
        allIsFetched: false,
        activeRequests: {},
        showSiteFilter: false
    }),

    computed: {
        colors() {
            return window.brandColors;
        },

        gradient() {
            return this.$cookies.get('brand_color') || '#2E457C';
        },

        refs() {
            this.$refs;
        },

        filterCharts() {
            return this.includes.filter((include) => {
                return include.filter;
            })
        },

        filterOptions() {
            if (! store.getters['filter/fetched']) {
                store.dispatch('filter/fetchAllOptions')
            }
            return store.getters['filter/options']
        },

        filterLayout() {
            return [
                {
                    label: 'Date',
                    key: 'date',
                    type: 'date-range'
                },
                {
                    label: 'Carrier',
                    key: 'carrier',
                    type: 'multi-select',
                    options: this.filterOptions.carriers
                },
                {
                    label: 'Transport mode',
                    key: 'transport_mode',
                    type: 'multi-select',
                    options: this.filterOptions.transportModes
                },
                {
                    label: 'Site',
                    key: 'site',
                    type: 'multi-select',
                    options: this.filterOptions.sites,
                    active: this.showSiteFilter
                },
                {
                    label: 'From country',
                    key: 'from_country',
                    type: 'multi-select',
                    options: this.filterOptions.fromCountries
                },
                {
                    label: 'From city',
                    key: 'from_city',
                    type: 'multi-select',
                    options: this.filterOptions.fromCities
                },
                {
                    label: 'To countries',
                    key: 'to_country',
                    type: 'multi-select',
                    options: this.filterOptions.toCountries
                },
                {
                    label: 'To city',
                    key: 'to_city',
                    type: 'multi-select',
                    options: this.filterOptions.toCities
                },
            ]
        }

    },

    methods: {
        trendSum(data) {
            let count = 0;

            data.datasets[0].data.forEach((value => {
                count += value;
            }));

            return count;
        },

        async fetchAllReports(url, filterParams) {
            const whatToFetch = this.allIsFetched ? this.filterCharts : this.includes;

            whatToFetch.forEach((include) => {
                this.fetchReport(include, url, filterParams)
            })

            this.allIsFetched = true;
            return whatToFetch;
        },


        async fetchReport(include, url, filterParams) {
            const vm = this;
            this.activeRequests[include.id] = axios.CancelToken.source();

            if (this.report[include.id]) {
                delete this.report[include.id];
            }

            url = url + `/${include.route}`;

            url = url + '?';
            if (include.filter) {
                url = url + filterParams;
            }

            const result = await axios.get(url, { cancelToken: this.activeRequests[include.id].token })
                .catch(error => {
                    console.error(error);
                });

            if (! result) { // If the request was cancelled.
                return false;
            }

            // UPDATE VUE3
            this.$set(this.report, include.id, result.data.data[include.responseKey ? include.responseKey : include.id]);

            if (include.ref) {
                this.updateChart(include.ref);
            }

            if (include.refGroup) {
                include.refGroup.forEach((ref) => {
                    vm.updateChart(ref);
                })
            }

            delete this.activeRequests[include.id]
        },

        cancelAllRequests() {
            Object.keys(this.activeRequests).forEach((request) => {
                this.activeRequests[request].cancel();
            })
        },

        updateChart(ref) {
            const vm = this;

            this.$nextTick(() => {
                if (vm.$refs[ref]) {
                    vm.$refs[ref].updateChart()
                }
            })
        },

        setDataset(data) {
            return data ? data : null;
        },

        mapTopList(series, label = 'name') {
            if (! series) {
                return [];
            }

            let totalCount = 0;

            series.forEach((data) => {
                totalCount += parseInt(data.count);
            })

            return series.map((data) => {
                return {
                    label: data[label],
                    code: data.code,
                    percentage: (data.count / totalCount) * 100,
                    count: data.count,
                }
            });
        },

        mapLayers(series) {

            const features = series.map(row => {

                return {
                    type: "Feature",
                    id: row.code,
                    properties: {
                        name: row.code
                    },
                    geometry: JSON.parse(row.geometry),
                }
            });

            return [
                {
                    id: "countryBorder",
                    type: "fill",
                    layout: {},
                    paint: {
                        "fill-color": this.$cookies.get('brand_color') || '#2E457C',
                        "fill-opacity": 0.8
                    },
                    source: {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: features
                        }
                    }
                }
            ];
        },
    }
}