<template>
    <div class="power-bi__wrapper">
        <div v-if="isShowErrorMessage" class="power-bi__error text-center flex flex-column justify-center h-full" >
            <p class="w-full text-center" v-text="errorMessage" />
        </div>
        <div v-else ref="powerBiContainer" :powerbi-access-token="powerBiAccessToken" class="flex flex-col flex-grow" />
    </div>
</template>

<script>
    import * as pbi from 'powerbi-client';
    import axios from "axios";
    import helper from '~/plugins/helper.js';
    import { DEFAULT_DASHBOARD_REPORT_SETTINGS } from '@/data/power-bi.js';

    const defaultErrorMessage = 'Your organization doesn\'t have a default report';

    export default {

        mixins: [helper],

        metaInfo() {
            return { title: this.$t("home") };
        },

        data: () => ({
            powerBiAccessToken: '',
            errorMessage: defaultErrorMessage,
            isShowErrorMessage: false,
        }),

        async mounted() {
            this.isShowErrorText = false;

            const models = pbi.models;
            let permissions = models.Permissions.All;

            try {
                const { data: { data } } = await axios.get(this.$apiUrl.powerBi.default);

                this.powerBiAccessToken = data.access_token;
                const reportId = data.embed_url
                    .split('&')
                    .find(arg => arg.indexOf('reportId') !== -1)
                    .split('=')[1];

                let config = {
                    accessToken: this.powerBiAccessToken,
                    embedUrl: data.embed_url,
                    id: reportId,
                    type: 'report',
                    tokenType: models.TokenType.Embed,
                    permissions,
                    settings: DEFAULT_DASHBOARD_REPORT_SETTINGS
                };

                const powerbi = new pbi.service.Service(
                    pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory,
                );
                const report = powerbi.embed(this.$refs.powerBiContainer, config);

                report.off("loaded");
                report.off("rendered");
                report.on("error", function (error) {
                    console.warn('error', error);
                });
            } catch (error) {
                if (error?.response?.status === 404) {
                    this.isShowErrorMessage = true;
                    this.errorMessage = defaultErrorMessage;

                    return;
                }

                if (error?.response?.data?.message) {
                    this.isShowErrorMessage = true;
                    this.errorMessage = error.response.data.message;
                }
            }
            
            
        },
    };
</script>
<style lang="scss">
@import '~/styles/components/_power-bi.scss';
</style>